const index = document.querySelector("#index");
const services = document.querySelector("#services");
const about = document.querySelector("#about");
const contact = document.querySelector("#contact");

const navBar = document.querySelector("nav");
const hero = document.querySelector(".mainHero");
const sections = document.querySelectorAll(".main-flex .feature-flex");
const aboutSections = document.querySelectorAll(".aboutSections .aboutSection");

const options = {
  threshold: 0.2,
};
const indexOptions = {
  rootMargin: "-10%",
};

// For index features
const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      return;
    }
    entry.target.classList.toggle("off-screen");
    observer.unobserve(entry.target);
  });
}, options);

// For navbar
const observerNav = new IntersectionObserver((entries, indexObserver) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      navBar.classList.add("nav-scrolled");
    } else {
      navBar.classList.remove("nav-scrolled");
    }
  });
}, indexOptions);

observerNav.observe(hero);

if (index) {
  sections.forEach((section) => {
    observer.observe(section);
  });
} else if (services) {
  console.log("this is the service page");
} else if (about) {
  aboutSections.forEach((section) => {
    observer.observe(section);
  });
} else if (contact) {
  console.log("this is the contact page");
  // form validation
  const form = document.getElementById("contactForm");
  const formHoney = document.getElementById("mainName");
  const name = document.getElementById("name");
  const email = document.getElementById("email");
  const message = document.getElementById("message");
  const checkBox = document.getElementById("invalidCheck");
  const formResponse = document.getElementById("formResponse");
  form.addEventListener("submit", (e) => {
    e.preventDefault();
    // Escape if the honeypot has been filled
    console.log(formHoney.value);
    if (!!formHoney.value) {
      return;
    }
    checkinputs();

    const successForm = document.querySelectorAll(".success");
    if (successForm.length === 4) {
      console.log("form valid");
      getFormData();
    }
  });

  const checkinputs = () => {
    const nameValue = name.value.trim();
    const emailValue = email.value.trim();
    const messageValue = message.value.trim();
    const checkValue = checkBox.checked;

    if (nameValue === "") {
      setErrorFor(name, "Name is required.");
    } else if (nameValue.length < 4) {
      setErrorFor(name, "Name must be longer than 4 characters.");
    } else {
      setSuccessFor(name);
    }
    if (emailValue === "") {
      setErrorFor(email, "Email is required.");
    } else if (!isEmail(emailValue)) {
      setErrorFor(email, "Email is not valid.");
    } else {
      setSuccessFor(email);
    }
    if (messageValue === "") {
      setErrorFor(message, "Message is required.");
    } else if (messageValue.length < 30) {
      setErrorFor(message, "Message must be longer than 30 characters.");
    } else {
      setSuccessFor(message);
    }
    if (checkValue === false) {
      setErrorFor(checkBox, "Please agree to terms & conditions.");
    } else {
      setSuccessFor(checkBox);
    }
  };
  const setErrorFor = (input, message) => {
    const formGroupName = input.parentElement;
    const small = formGroupName.querySelector("small");
    formGroupName.className = "form-group error";
    small.innerText = message;
  };
  const setSuccessFor = (input) => {
    const formGroupName = input.parentElement;
    formGroupName.className = "form-group success";
  };
  const isEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  const getFormData = () => {
    const data = {};
    const formElements = Array.from(form);
    formElements.splice(4, 2);
    formElements.map((input) => (data[input.name] = input.value));
    console.log(JSON.stringify(data));

    // Construct an HTTP request
    var xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

    // Send the collected data as JSON
    xhr.send(JSON.stringify(data));

    // Callback function
    xhr.onloadend = (response) => {
      if (response.target.status === 200) {
        // The form submission was successful
        form.reset();
        formResponse.className = "success";
        formResponse.innerHTML =
          "Thanks for the message. I’ll be in touch shortly.";
        setTimeout(() => {
          formResponse.style.visibility = "hidden";
        }, 3000);
      } else {
        // The form submission failed
        formResponse.innerHTML =
          "Something went wrong. Please try again later or give us a call.";
        formResponse.className = "error";
        setTimeout(() => {
          formResponse.style.visibility = "hidden";
        }, 3000);
        console.error(JSON.parse(response.target.response).message);
      }
    };
  };
}
